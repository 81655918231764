<template>
  <div class="backs">
    <!--  顶部tab切换  -->
    <a-tabs v-model="tabValue" @change="tabChange">
      <a-tab-pane v-for="item in tabList" :key="item.key" :tab="item.tabName" />
    </a-tabs>
    <div style="margin-bottom: 18px;">
      <a-form-model :label-col="{style: 'width: 80px'}" layout="inline">
        <a-form-model-item v-if="tabValue === '1'" label="操作模块">
          <a-select
            v-model="form.operateType"
            placeholder="请选择操作模块"
            style="width: 140px"
            allow-clear
          >
            <a-select-option v-for="it in operateTypeList" :key="it.codeKey" :value="it.codeKey">
              {{ it.content }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="操作时间">
          <div class="date_picker">
            <a-date-picker v-model="form.startTime" show-time placeholder="开始日期" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clea style="width: 300px" />
            <span class="date_picker_span">~</span>
            <a-date-picker v-model="form.endTime" show-time placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear style="width: 300px" />
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="subminQuery">查询</a-button>
          <a-button class="button_close" @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      :row-key="tableRowKey"
      table-ref=".backs"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    />
  </div>
</template>

<script>
import { getTenantCode } from '@/services/dropDownInput/index.js'
import { operateRecord, getRecordList } from '@/services/globalSetting/locationOfWork.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'GlobalSettingOperationDate',
  mixins: [mixins],
  data() {
    return {
      tabValue: '1',
      tabList: [
        { key: '1', tabName: '业务日志' },
        { key: '2', tabName: '登录日志' }
      ],
      form: {
        operateType: undefined,
        startTime: undefined,
        endTime: undefined
      },
      allColumns: {
        'operateTime': {
          title: '操作时间',
          dataIndex: 'operateTime',
          key: 'operateTime',
          width: 200
        },
        'operateName': {
          title: '操作姓名',
          dataIndex: 'operateName',
          key: 'operateName',
          width: 200
        },
        'operateByName': {
          title: '操作姓名',
          dataIndex: 'operateByName',
          key: 'operateByName',
          width: 200
        },
        'operateTypeName': {
          title: '操作名称',
          dataIndex: 'operateTypeName',
          key: 'operateTypeName',
          width: 200
        },
        'operateContent': {
          title: '操作内容',
          dataIndex: 'operateContent',
          key: 'operateContent'
        }
      },
      columns: [],
      dataSource: [],
      operateTypeList: []
    }
  },
  mounted() {
    this.getTenantCode()
    this.combinationColumns()
  },
  methods: {
    tableRowKey(record) {
      let num = ''
      for (let i = 0; i < 12; i++) {
        num += String(Math.random() * 10)
      }
      return num
    },
    // tab表头动态匹配
    combinationColumns() {
      this.columns = []
      const config = {
        '1': {
          baseColumn: ['operateTime', 'operateByName', 'operateTypeName', 'operateContent']
        },
        '2': {
          baseColumn: ['operateTime', 'operateName', 'operateContent']
        }
      }
      const baseColumn = config[this.tabValue].baseColumn
      for (let i = 0; i < baseColumn.length; i++) {
        const obj = JSON.parse(JSON.stringify(this.allColumns[baseColumn[i]]))
        this.columns.push(obj)
      }
    },
    async fetchDataApiFn(param) {
      const config = {
        '1': getRecordList,
        '2': operateRecord
      }
      const res = await config[this.tabValue](param)
      this.dataSource = res.data.records
      this.total = res.data.total
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
    },
    // 获取操作模块list
    async getTenantCode() {
      const res = await getTenantCode({ codeName: 'operate_module' })
      this.operateTypeList = res.data
    },
    // tab切换change
    tabChange() {
      this.dataSource = []
      this._queryParam = {}
      this.init()
      this.combinationColumns()
      this.serchGetData()
      if (this.tabValue === '1') {
        this.getTenantCode()
      }
    },
    // 查询
    subminQuery() {
      const { startTime, endTime } = this.form
      if (startTime && !endTime) {
        this.$message.warning('请选择结束日期')
        return
      }
      if (!startTime && endTime) {
        this.$message.warning('请选择开始日期')
        return
      }
      this._queryParam = {
        ...this.form
      }
      this.serchGetData()
    },
    // 重置
    reset() {
      this.init()
      this.$nextTick(() => {
        this._queryParam = {
          ...this.form
        }
        this.serchGetData()
      })
    },
    init() {
      this.form = {
        operateType: undefined,
        startTime: undefined,
        endTime: undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
.backs{
  background-color: #fff;
  padding: 20px 20px 0;
  min-height: 100%;
  .date_picker {
    display: flex;
    align-items: center;
    &_span {
      margin: 0 10px;
    }
  }
  .button {
    margin-top: 5px;
    &_close {
      margin-left: 20px;
    }
  }
}
</style>
